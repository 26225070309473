




















































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { format, parseISO } from 'date-fns';
import InvoiceView from '@/views/invoices/InvoiceView.vue';
import { InvoiceableReceipt } from '@/common/types';
import { downloadPdf } from '@/api/invoices';

@Component({
  components: { InvoiceView },
})
export default class DebtorItem extends Vue {
  @Prop({ required: true, type: Object as () => InvoiceableReceipt })
  public receipt!: InvoiceableReceipt;

  @Prop({ required: true, type: Number })
  public establishmentId!: number;

  @Prop({ type: Boolean, default: false })
  public allowSend!: boolean;

  public loading = false;
  public error = '';
  public showSidebar = false;

  get invoice() {
    return this.receipt.invoice;
  }

  get receiptDate() {
    const d = parseISO(this.receipt.date);
    return format(d, 'dd-MM-yyyy');
  }

  get debtorName() {
    if (this.receipt?.invoice?.debtorName) {
      return this.receipt.invoice.debtorName;
    } else if (this.receipt.identification) {
      return this.receipt.identification;
    } else {
      return 'ONBEKEND';
    }
  }

  public async mounted() {
    try {
      this.loading = true;
    } catch (e) {
      this.error = e;
    } finally {
      this.loading = false;
    }
  }

  @Emit('refresh')
  public refresh() {
    this.showSidebar = false;
    return true;
  }

  public downloadPdf() {
    if (this.invoice) {
      downloadPdf(this.invoice.id);
    }
  }
}
